<template>
   <div id="app">
    <v-app>
      <!-- Forgotten Password Form -->
      <img class="form-img" src="@/assets/forgotten.jpg" />
      <div class="right">
        <success-alert
        :success="forgottenPassword_Success"
        class="font-weight-black white--text"
      >
        <template #success-message>
          {{ successMessage }}        
        </template>
      </success-alert>
      <error-alert
        :error="error"
        :error-message="errorMessage"
        @error-closed="error=false"
      ></error-alert>
        <div class="content">
          <img :src="!isScreenLessThan850 ? require('@/assets/logoHorTransp.png') : require('@/assets/logoHor.png')"/>
          <!-- <h1 id="main-title" class="pb-16 logo">RiverTemp</h1> -->
          <h4 id="forgotten-title">Write your email and we will sent you a link to reset your password</h4>
          <form id="form-login"
           method="post" onsubmit="return false">
            <email-field
              :dark="isScreenLessThan850"
              @email-input="email = $event"
            ></email-field>
            <div class="form-element">
              <v-btn 
                id="logIn" 
                class="white--text text-capitalize" 
                type="submit" 
                dense
                name="login" 
                color="#19A7CE"
                :disabled="!$v.email.email || !email"
                @click="sendEmailForForgottenPassword"
              >
                Send
              </v-btn>
              <v-btn 
                id="goToLogin" 
                color="transparent"
                elevation="0"
                class="off text-capitalize" 
                name="signup" 
                @click="$router.push('/login')"
              >
                Go to Login
              </v-btn>
            </div>
          </form>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
  import { withAsync } from              "@/helpers/withAsync";
  import { apiStatus } from             "@/api/constants/apiStatus";
  import { apiStatusComputed } from     "@/api/helpers/computedApiStatus";
  import { apiObject } from             "@/api/api";
  import { forgottenPassword } from     "@/api/authenticationApi.js";
  import { required, email } from       "vuelidate/lib/validators";
  import ErrorAlert from "@/components/ErrorAlert.vue";
  import SuccessAlert from "@/components/SuccessAlert.vue";
  import EmailField from "@/components/EmailField.vue";

  export default {
    components: { ErrorAlert, SuccessAlert, EmailField },

    name: "ForgottenPassword",

    data(){
      return{
        errorMessages:{
          requiredMessage:               "This field is required",
          emailErrorMessage:             "Must be a valid email",
        },
        email:                            null,
        error:                            false,
        success:                          false,
        successMessage:                   null,
        errorMessage:                     "",
        screenWidth:                      window.innerWidth,
        forgottenPassword:                apiStatus.Idle,
      }
    },

    validations: {

      email: { required, email }
    },

    computed:{ 

      isScreenLessThan850() {
        return this.screenWidth < 850;
      },
      
      emailErrors(){
        const errors = [];
        if (!this.$v.email.$dirty)return errors;
        !this.$v.email.required && errors.push(this.errorMessages.requiredMessage);
        !this.$v.email.email && errors.push(this.errorMessages.emailErrorMessage);
        return errors;
      },
      ...apiStatusComputed(["forgottenPassword"])
    },

    methods:{
      
      async sendEmailForForgottenPassword(){
        const payload = {
          email: this.email,
        }

        this.forgottenPassword =          apiStatus.Pending;

        const {response, error} =         await withAsync(forgottenPassword, payload);

        if(error){       
          console.log(error)         
          this.forgottenPassword =        apiStatus.Error;
          this.error =                    true;
          this.errorMessage =             error.response.data.metadata.response_message;
          this.$emit("error-message", this.errorMessage);
          return this.errorMessage;
        }


        this.forgottenPassword =           apiStatus.Success;
        let apiResponse = response.data.metadata.response_message;
        this.successMessage = apiResponse;
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "@/plugins/login.scss";
  
  .form-label{
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
  }
  .form-img{
    width:50%;
    height: 100%;
  }
  .logo{
    color:#19A7CE;
    font-family: 'Rubik', sans-serif;
    font-size: 80px;
  }
  .signup-btn{
    background-color: white;
    color: #19A7CE;
  }

  h4{
    color: #19A7CE;
    font-family: 'Rubik', sans-serif;
    font-size: 16px;
  }

  .off{
    color: #19A7CE;
  }
</style>